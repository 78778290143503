import React, { useState, useEffect, useRef } from "react";
import style from "./productsChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import AddImg from "../../assets/icons8-add-24.png";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import {
  productsDataAdded,
  productsDataUpdate,
  getCategoryData,
  productsEditAction,
  postEditAddVariant,
} from "../../store/slices/productsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import { message as productChangeMessage } from "antd";
import BackButton from "../backButton/BackButton";
import InputError from "../inputError/InputError";
const { TextArea } = Input;
let nextId = 0;
const ProductsChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const urls = "/products/add-products";
  const navigate = useNavigate();
  const loader = useSelector((state) => state.products.loader);
  const loading = useSelector((state) => state.products.deleteLoad);
  const message = useSelector((state) => state.products.message);
  const singleProducts = useSelector((state) => state.products.singleProducts);
  const categoryData = useSelector((state) => state.products.category);
  const editData = useSelector((state) => state.products.editData);
  const [category, setCategory] = useState();
  const [updateCategory, setUpdateCategory] = useState();
  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState();
  const [discountType, setDiscountType] = useState();
  const [image, setImage] = useState([]);
  const [newImage, setNewImage] = useState([]);
  const [addNewImage, setAddNewImage] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [isFormListComplete, setIsFormListComplete] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    name: [0],
    size: [0],
    price: [0],
    // quantity: [0],
    // productType: [0],
    colour: [0],
    specifications: [0],
    material: [0],
    category: [0],
    details: [0],
    // description: [0],
    images: [0],
  });

  useEffect(() => {
    console.log("inputErrors", inputErrors);
  }, [inputErrors]);
  const [addForm, setAddForm] = useState([
    {
      name: "",
      size: "",
      price: "",
      // quantity: "",
      // productType: "",
      colour: "",
      specifications: "",
      discountedPrice: "",
      material: "",
      category: "",
      details: "",
      // description: "",
      discountType: "",
      discount: "",
      images: [],
    },
  ]);
  const fileInputRef = useRef(null);
  // const fileInputRef2 = useRef(null);
  const fileInputRefs = useRef([]);
  const [editFormVariantsAddForm, setEditFormVariantsAddForm] = useState([]);

  useEffect(() => {
    console.log("editFormVariantsAddForm", editFormVariantsAddForm);
  }, [editFormVariantsAddForm]);
  // useEffect(() => {
  //   console.log("fileInputRef", fileInputRef2);
  // }, [fileInputRef2]);
  useEffect(() => {
    console.log("fileInputRefs", fileInputRefs);
  }, [fileInputRefs]);

  function handleAddVariantOnEdit() {
    setEditFormVariantsAddForm([
      ...editFormVariantsAddForm,
      {
        name: editData ? editData?.name : "",
        size: "",
        price: "",
        quantity: "",
        productType: editData ? editData?.productType : "",
        colour: "",
        specifications: editData ? editData?.specifications : "",
        discountedPrice: "",
        material: "",
        category: editData ? editData?.category : "",
        details: editData ? editData?.details : "",
        description: "",
        discountType: "",
        discount: "",
        images: [],
      },
    ]);
  }

  function handleSubmitVariants() {
    // checkFormCompletion;
    const variants = editFormVariantsAddForm.map((form) => {
      return {
        ...form,
        parentId: editData?._id,
        categoryId: editData?.categoryId,
      };
    });
    console.log("==>", editFormVariantsAddForm, variants);
    dispatch(postEditAddVariant(variants, setEditFormVariantsAddForm));
    // const temp = !(await loader);
    // if (!temp) {
    //   setEditFormVariantsAddForm([]);
    // }
  }

  // function onFinishVariant(values) {
  //   console.log("values+", values);
  // }

  function handle(e, idx) {
    console.log(e.target.value);
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleButtonClickEditVariant = (idx) => {
    // console.log("CLICKED !!!");
    // fileInputRef2.current.click();
    fileInputRefs.current[idx].click();
  };

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];

      if (!allowedTypes.includes(file.type)) {
        productChangeMessage.error(
          "Invalid file type. Please select an image."
        );
        reject(false);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const minWidth = 1536;
          const minHeight = 956;

          if (img.width >= minWidth && img.height >= minHeight) {
            resolve(true);
          } else {
            productChangeMessage.error(
              "Invalid dimensions for image. Please select an image with a width of 1536 pixels and a height of 956 pixels."
            );
            reject(false);
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  function checkFormCompletion(formList) {
    for (let i = 0; i < formList.length; i++) {
      const form = formList[i];
      for (const [key, value] of Object.entries(form)) {
        if (
          key === "discount" ||
          key === "discountType" ||
          key === "discountedPrice" ||
          key === "quantity" ||
          key === "productType" ||
          key === "description"
        ) {
          continue;
        }
        if (!value.length) {
          const element = inputErrors[key].find((ele) => ele === i);
          if (typeof element === "undefined") {
            setInputErrors({ ...inputErrors, [key]: [...inputErrors[key], i] });
          }
        } else {
          const element = inputErrors[key].find((ele) => ele === i);
          if (typeof element !== "undefined") {
            const newErrorsAtKey = inputErrors[key].filter((ele) => ele !== i);
            setInputErrors({ ...inputErrors, [key]: newErrorsAtKey });
          }
        }
      }
    }
  }
  const variantDelete = (indexs) => {
    let data = [...addForm];
    let updateData = data.filter((item, index) => index !== indexs);
    setAddForm(updateData);
  };

  const addFormHnadler = () => {
    // const newItem = {
    //   name: "",
    //   size: "",
    //   price: "",
    //   quantity: "",
    //   productType: "",
    //   colour: "",
    //   specifications: "",
    //   discountedPrice: "",
    //   material: "",
    //   category: "",
    //   details: "",
    //   discountType: "",
    //   discount: "",
    //   image: "",
    // };

    const newItem = {
      name: addForm[0].name,
      size: "",
      price: "",
      // quantity: "",
      // productType: addForm[0].productType,
      colour: "",
      specifications: addForm[0].specifications,
      discountedPrice: "",
      material: "",
      category: addForm[0].category,
      details: addForm[0].details,
      // description: "",
      discountType: "",
      discount: "",
      images: [],
    };

    setAddForm((existingItems) => [...existingItems, newItem]);
  };

  const variantChange = (e, index) => {
    const newArray = addForm.map((item, i) => {
      if (index === i) {
        if (e.target.name === "quantity") {
          const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
          return { ...item, [e.target.name]: sanitizedValue };
        } else if (e.target.name === "price" || e.target.name === "discount") {
          const sanitizedValue = e.target.value.replace(
            /[^0-9.]|(\.)\1+/g,
            "$1"
          );
          return { ...item, [e.target.name]: sanitizedValue };
        } else {
          return { ...item, [e.target.name]: e.target.value };
        }
      } else {
        return item;
      }
    });
    setAddForm(newArray);

    const variantArray = newArray.map((form, i) => {
      if (i === 0) {
        return form;
      } else {
        let newForm = { ...form };
        for (const key in form) {
          if (
            key === "name" ||
            key === "productType" ||
            key === "specifications" ||
            key === "category" ||
            key === "details"
          ) {
            const value = newArray[0][key];
            newForm = { ...newForm, [key]: value };
          }
        }
        return newForm;
      }
    });

    setAddForm(variantArray);
    if (e.target.name === "price") {
      const sanitizedValue = e.target.value.replace(/[^0-9.]|(\.)\1+/g, "$1");
      setPrice(sanitizedValue);
    }
    if (e.target.name === "discountType") {
      setDiscountType(e.target.value);
    }
    if (e.target.name === "discount") {
      const sanitizedValue = e.target.value.replace(/[^0-9.]|(\.)\1+/g, "$1");
      setDiscount(sanitizedValue);
    }

    if (
      e.target.name === "discount" ||
      e.target.name === "price" ||
      e.target.name === "discountType"
    ) {
      if (e.target.name === "discount") {
        const sanitizedValue = e.target.value.replace(/[^0-9.]|(\.)\1+/g, "$1");
        const disc = sanitizedValue;
        const pri = addForm[index].price;
        const discType = addForm[index].discountType;

        let discPrice = "";

        if (
          Number(disc) > 0 &&
          Number(pri) > 0 &&
          discType.length &&
          discType !== "select discount type"
        ) {
          if (discType === "Percentage") {
            discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
          } else if (discType === "Flat") {
            discPrice = Number(pri) - Number(disc);
          }
        }

        if (!Number(discPrice) && Number(pri) > 0) {
          discPrice = Number(pri);
        }

        const updatedData = addForm.map((item, i) => {
          return index === i
            ? {
              ...item,
              discountedPrice: discPrice,
              discount: disc,
            }
            : item;
        });
        setAddForm(updatedData);
      } else if (e.target.name === "price") {
        const sanitizedValue = e.target.value.replace(/[^0-9.]|(\.)\1+/g, "$1");
        const pri = sanitizedValue;
        const disc = addForm[index].discount;
        const discType = addForm[index].discountType;

        let discPrice = "";

        if (
          Number(disc) > 0 &&
          Number(pri) > 0 &&
          discType.length &&
          discType !== "select discount type"
        ) {
          if (discType === "Percentage") {
            discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
          } else if (discType === "Flat") {
            discPrice = Number(pri) - Number(disc);
          }
        }

        if (!Number(discPrice) && Number(pri) > 0) {
          discPrice = Number(pri);
        }

        const updatedData = addForm.map((item, i) => {
          return index === i
            ? { ...item, discountedPrice: discPrice, price: pri }
            : item;
        });
        setAddForm(updatedData);
      } else if (e.target.name === "discountType") {
        let sanitizedValue = "";
        if (e.target.value === "select discount type") {
          sanitizedValue = "";
        } else {
          sanitizedValue = e.target.value;
        }
        const disc = addForm[index].discount;
        const pri = addForm[index].price;
        const discType = sanitizedValue;

        let discPrice = "";

        if (
          Number(disc) > 0 &&
          Number(pri) > 0 &&
          discType.length &&
          discType !== "select discount type"
        ) {
          if (discType === "Percentage") {
            discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
          } else if (discType === "Flat") {
            discPrice = Number(pri) - Number(disc);
          }
        }

        if (!Number(discPrice) && Number(pri) > 0) {
          discPrice = Number(pri);
        }

        const updatedData = addForm.map((item, i) => {
          return index === i
            ? {
              ...item,
              discountedPrice: discPrice,
              discountType: discType,
            }
            : item;
        });
        setAddForm(updatedData);
      }
    }
  };

  function calcDiscountedPrice(form) {
    const pri = form.price;
    const disc = form.discount;
    const discType = form.discountType;

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    if (!Number(discPrice) && Number(pri) > 0) {
      discPrice = Number(pri);
    }

    return { ...form, discountedPrice: discPrice };
  }

  const addVariantChange = (e, index, fieldName) => {
    // const { name, value } = e.target;
    // console.log(e);
    let value = "";
    if (fieldName === "discountType" || fieldName === "material") {
      value = e;
    } else {
      value = e.target.value;
    }

    // if (fieldName === "price") {
    //   const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    //   // form.setFieldsValue({ price: sanitizedValue });

    //   const pri = sanitizedValue;
    //   // const disc = form.getFieldValue("discountAdd");
    //   const disc = editFormVariantsAddForm[index].discount;
    //   // const discType = form.getFieldValue("discountType");
    //   const discType = editFormVariantsAddForm[index].discountType;

    //   let discPrice = "";

    //   if (
    //     Number(disc) > 0 &&
    //     Number(pri) > 0 &&
    //     discType.length &&
    //     discType !== "select discount type"
    //   ) {
    //     if (discType === "Percentage") {
    //       discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
    //     } else if (discType === "Flat") {
    //       discPrice = Number(pri) - Number(disc);
    //     }
    //   }

    //   if (!Number(discPrice) && Number(pri) > 0) {
    //     discPrice = Number(pri);
    //   }

    //   // form.setFieldsValue({ discount: discPrice });
    //   //   setEditFormVariantsAddForm((prev) =>
    //   //     prev.map((form, idx) => {
    //   //       if (idx !== index) {
    //   //         return form;
    //   //       } else {
    //   //         const newForm = { ...form, discountedPrice: discPrice };
    //   //         return newForm;
    //   //       }
    //   //     })
    //   //   );
    // }

    console.log("++", fieldName, value);
    setEditFormVariantsAddForm((prev) =>
      prev.map((form, idx) => {
        if (idx !== index) {
          return form;
        } else {
          let newForm = { ...form, [fieldName]: value };
          newForm = calcDiscountedPrice(newForm);
          // let newForm2 = { ...newForm };
          // if (fieldName === "price") {
          //   newForm2 = { ...newForm, discountedPrice: value };
          // }
          console.log(newForm);
          return newForm;
        }
      })
    );
    // editFormVariantsAddForm[index][fieldName] = value;
  };

  function handleEditQuantityChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    form.setFieldsValue({ quantity: sanitizedValue });
  }

  // const updateDiscountHanddler = (e) => {
  //   let discounts = e.target.value;
  //   let prices = form.getFieldValue("price");
  //   let discountTypes = form.getFieldValue("discountType");
  //   if (discountTypes === "Percentage") {
  //     var discountedPrice =
  //       Number(prices) - Number(prices) * (Number(discounts) / 100);
  //   } else {
  //     var discountedPrice = Number(prices) - Number(discounts);
  //   }
  //   form.setFieldValue("discount", discountedPrice);
  // };
  // const updateDiscountHanddlerPrice = (e) => {
  //   let discounts = form.getFieldValue("discountAdd");
  //   let prices = e.target.value;
  //   let discountTypes = form.getFieldValue("discountType");
  //   if (discountTypes === "Percentage") {
  //     var discountedPrice =
  //       Number(prices) - Number(prices) * (Number(discounts) / 100);
  //   } else {
  //     var discountedPrice = Number(prices) - Number(discounts);
  //   }
  //   form.setFieldValue("discount", discountedPrice);
  // };

  function handleEditDiscountChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    form.setFieldsValue({ discountAdd: sanitizedValue });

    const pri = form.getFieldValue("price");
    const disc = sanitizedValue;
    const discType = form.getFieldValue("discountType");

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    if (!Number(discPrice) && Number(pri) > 0) {
      discPrice = Number(pri);
    }

    form.setFieldsValue({ discount: discPrice });
  }

  function handleEditPriceChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    form.setFieldsValue({ price: sanitizedValue });

    const pri = sanitizedValue;
    const disc = form.getFieldValue("discountAdd");
    const discType = form.getFieldValue("discountType");

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    if (!Number(discPrice) && Number(pri) > 0) {
      discPrice = Number(pri);
    }

    form.setFieldsValue({ discount: discPrice });
  }
  const onChangeSelect = (e) => {
    const pri = form.getFieldValue("price");
    const disc = form.getFieldValue("discountAdd");
    const discType = e;

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    form.setFieldsValue({ discount: discPrice });
  };
  const onSearch = (value) => { };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const sizeData = [
    { id: 1, value: "6x6" },
    { id: 2, value: "5x6" },
    { id: 3, value: "6x6.5" },
  ];
  const discountData = [
    { id: 1, value: "Percentage" },
    { id: 2, value: "Flat" },
  ];
  const materialData = [
    { id: 1, value: "Suede" },
    { id: 2, value: "leatherette" },
    { id: 3, value: "linen" },
    { id: 4, value: "Laminate" },
    { id: 5, value: "Veneer" },
    { id: 6, value: "PVC" },
    { id: 7, value: "Acrylic Sheets" },
    { id: 8, value: "Marble" },
  ];

  const backHandler = () => {
    navigate("/products");
  };
  const onChangeDate = () => { };
  const onFinish = (values) => {
    let categoryId = "";
    const categorySelected = categoryData.find(
      (category) => category.title === values.category
    );

    if (typeof categorySelected !== "undefined") {
      categoryId = categorySelected._id;
    }
    let idData = path.split("/");
    let id = idData[idData.length - 1];
    let dataUpdate = {
      name: values.name,
      category: values.category,
      categoryId: categoryId,
      size: values.size,
      colour: values.colour,
      price: values.price,
      // productType: values.type,
      // quantity: values.quantity,
      material: values.material,
      details: values.details,
      // description: values.description,
      specifications: values.specifications,
      discountedPrice: values.discount,
      discountType: values.discountType,
      discount: values.discountAdd,
      images: newImage ? newImage : image,
    };

    let sanitizedForm = {};

    for (const key in dataUpdate) {
      sanitizedForm[key] = dataUpdate[key];
    }

    // if (
    //   Number(form.discount) > 0 &&
    //   (form.discountType === "Percentage" || form.discountType === "Flat")
    // ) {
    //   sanitizedForm = dataUpdate;
    // } else {
    //   for (const key in dataUpdate) {
    //     // if (!(key === "discount") && !(key === "discountType")) {
    //     sanitizedForm[key] = dataUpdate[key];
    //     // }
    //   }
    // }
    // dispatch(
    //   productsDataUpdate(
    //     singleProducts[0]?._id ? singleProducts[0]?._id : id,
    //     dataUpdate
    //   )
    // );
    dispatch(
      productsDataUpdate(
        singleProducts[0]?._id ? singleProducts[0]?._id : id,
        sanitizedForm
      )
    );
  };
  const addDataProduct = () => {
    if (!isFormListComplete) {
      setShowInputErrors(true);
    } else {
      if (showInputErrors) {
        setShowInputErrors(false);
      }

      const data = [...addForm];
      const newData =
        data &&
        data.map((item) => {
          return { ...item, images: addNewImage };
        });

      const sanitizedForm = addForm.map((form) => {
        const { productType, quantity, description, ...rest } = form;
        // if (
        //   Number(form.discount) > 0 &&
        //   (form.discountType === "Percentage" || form.discountType === "Flat")
        // ) {
        //   return rest;
        // } else {
        //   let newForm = {};
        //   for (const key in rest) {
        //     if (!(key === "discount") && !(key === "discountType")) {
        //       newForm[key] = rest[key];
        //     }
        //   }
        //   return newForm;
        // }
        return rest;
      });

      // Category Id insert
      const formWithCategoryIds = sanitizedForm.map((form) => {
        let categoryId = "";
        const categorySelected = categoryData.find(
          (category) => category.title === form.category
        );

        if (typeof categorySelected !== "undefined") {
          categoryId = categorySelected._id;
        }

        return { ...form, categoryId: categoryId };
      });

      dispatch(productsDataAdded(formWithCategoryIds));
    }
  };

  const onFinishFailed = (errorInfo) => { };
  useEffect(() => {
    if (
      message === "Product added successfully!" ||
      message === "Product updated successfully!"
    ) {
      navigate("/products");
    }
  }, [message]);
  useEffect(() => {
    setAddForm([
      {
        name: "",
        size: "",
        price: "",
        quantity: "",
        productType: "",
        colour: "",
        specifications: "",
        discountedPrice: "",
        material: "",
        category: "",
        details: "",
        description: "",
        discountType: "",
        discount: "",
        images: [],
      },
    ]);
    setImage([]);
    setNewImage([]);
    setAddNewImage([]);
  }, []);
  useEffect(() => {
    let data = singleProducts[0];
    // debugger;
    form.setFieldValue("name", data?.name ? data?.name : "");
    form.setFieldValue("size", data?.size ? data?.size : "");
    form.setFieldValue(
      "description",
      data?.description ? data?.description : ""
    );
    form.setFieldValue("price", data?.price ? data?.price : "");
    form.setFieldValue("quantity", data?.quantity ? data?.quantity : "");
    form.setFieldValue("type", data?.productType ? data?.productType : "");
    form.setFieldValue("colour", data?.colour ? data?.colour : "");
    form.setFieldValue("details", data?.details ? data?.details : "");
    form.setFieldValue(
      "specifications",
      data?.specifications ? data?.specifications : ""
    );
    form.setFieldValue(
      "discount",
      data?.discountedPrice ? data?.discountedPrice : ""
    );
    form.setFieldValue("material", data?.material ? data?.material : "");
    form.setFieldValue("category", data?.category ? data?.category : "");
    form.setFieldValue(
      "discountType",
      data?.discountType ? data?.discountType : ""
    );
    form.setFieldValue("discountAdd", data?.discount ? data?.discount : "");
    // form.setFieldsValue({ images: data?.images?data?.images.map((item)=>item.image):''});

    // data?.images?.data?.images?.map(item=>setImage((previousImage)=>[...previousImage,item?.image]))

    setImage(data?.images ? data?.images : "");
    setNewImage(data?.images ? data?.images : "");
  }, [singleProducts]);
  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(productsEditAction(id));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    form.setFieldValue("name", data?.name ? data?.name : "");
    form.setFieldValue("size", data?.size ? data?.size : "");
    form.setFieldValue(
      "description",
      data?.description ? data?.description : ""
    );
    form.setFieldValue("price", data?.price ? data?.price : "");
    form.setFieldValue("quantity", data?.quantity ? data?.quantity : "");
    form.setFieldValue("type", data?.productType ? data?.productType : "");
    form.setFieldValue("colour", data?.colour ? data?.colour : "");
    form.setFieldValue("details", data?.details ? data?.details : "");
    form.setFieldValue(
      "specifications",
      data?.specifications ? data?.specifications : ""
    );
    form.setFieldValue(
      "discount",
      data?.discountedPrice ? data?.discountedPrice : ""
    );
    form.setFieldValue("material", data?.material ? data?.material : "");
    form.setFieldValue("category", data?.category ? data?.category : "");
    form.setFieldValue(
      "discountType",
      data?.discountType ? data?.discountType : ""
    );
    form.setFieldValue("discountAdd", data?.discount ? data?.discount : "");
    // form.setFieldsValue({ images: data?.images?data?.images.map((item)=>item.image):''});

    // data?.images?.data?.images?.map(item=>setImage((previousImage)=>[...previousImage,item?.image]))

    setImage(data?.images ? data?.images : "");
    setNewImage(data?.images ? data?.images : "");
  }, [editData]);
  const deleteImgHandler = (image) => {
    let images = [...newImage];

    let updateImages = images.filter((item) => item.image !== image);
    setNewImage(updateImages);
  };
  const deleteImgHandlerAdd = (indexd, imgSrc) => {
    let images = [...addForm[indexd].images];
    let updatedImages = images.filter((item) => item !== imgSrc);
    let formToBeUpdated = addForm[indexd];
    formToBeUpdated.images = updatedImages;
    setAddForm((prev) =>
      prev.map((form, idx) => {
        if (idx !== indexd) {
          return form;
        } else {
          return formToBeUpdated;
        }
      })
    );
  };

  const deleteImgHandlerEditVariant = (indexd, imgSrc) => {
    let images = [...editFormVariantsAddForm[indexd].images];
    let updatedImages = images.filter((item) => item !== imgSrc);
    let formToBeUpdated = editFormVariantsAddForm[indexd];
    formToBeUpdated.images = updatedImages;
    setEditFormVariantsAddForm((prev) =>
      prev.map((form, idx) => {
        if (idx !== indexd) {
          return form;
        } else {
          return formToBeUpdated;
        }
      })
    );
  };
  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        const updateImages = [
          ...image,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        const updateImagesNew = [
          ...newImage,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        const updateAdd = [
          ...addNewImage,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        setImage(updateImages);
        setNewImage(updateImagesNew);
        setAddNewImage(updateAdd);
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  useEffect(() => {
    dispatch(getCategoryData());
  }, []);
  useEffect(() => {
    const addCategoryOptions = categoryData.map((category) => {
      return category.title;
    });
    // setCategory(categoryData);
    setCategory(addCategoryOptions);
    const updateCategoryOptions = categoryData.map((item) => {
      return { value: item.title };
    });
    setUpdateCategory(updateCategoryOptions);
  }, [categoryData]);

  useEffect(() => {
    checkFormCompletion(addForm);
  }, [addForm]);

  useEffect(() => {
    if (Object.keys(inputErrors).length) {
      let isComplete = true;
      for (const key in inputErrors) {
        if (inputErrors[key].length) {
          isComplete = false;
          break;
        }
      }

      if (isFormListComplete !== isComplete) {
        setIsFormListComplete(isComplete);
      }
    }
  }, [inputErrors]);

  // const addImageUpload = async (e, index) => {
  //   try {
  //     const fileInput = e.target;
  //     const file = fileInput.files[0];
  //     if (file) {

  //       const allowedTypes = [
  //         "image/jpeg",
  //         "image/png",
  //         "image/gif",
  //         "image/webp",
  //       ];

  //       if (!allowedTypes.includes(file.type)) {
  //         // showError("Invalid file type. Please select an image.");
  //         productChangeMessage.error(
  //           "Invalid file type. Please select an image."
  //         );
  //         return;
  //       } else {
  //         // hideError();
  //       }

  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         const img = new Image();
  //         img.src = e.target.result;
  //         img.onload = () => {
  //           const width = img.width;
  //           const height = img.height;

  //           const minWidth = 1300; // Example minimum width
  //           const minHeight = 550; // Example minimum height

  //           if (width >= minWidth && height >= minHeight) {
  //             // hideError();
  //           } else {
  //             // showError(
  //             //   "Invalid dimensions for image. Please select an image with a width of 1500 pixels and a height of 550 pixels."
  //             // );
  //             productChangeMessage.error(
  //               "Invalid dimensions for image. Please select an image with a width of 1500 pixels and a height of 550 pixels."
  //             );
  //             return;
  //           }
  //         };
  //       };
  //       // }
  //       const formData = new FormData();
  //       formData.append("image", e.target.files[0]);

  //       const response = await axios.post(
  //         "https://uat-backend.trelif.com/api/v1/media/add-image",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${getToken()}`,
  //           },
  //         }
  //       );
  //       const img = [...addForm[index].images, response.data.data.image[0]];

  //       const updatedData = addForm.map((item, i) => {
  //         return index === i ? { ...item, images: img } : item;
  //       });
  //       setAddForm(updatedData);

  //       return response.data;
  //     }
  //   } catch (error) {

  //     throw error;
  //   }
  // };

  const addImageUpload = async (e, index) => {
    try {
      const fileInput = e.target;
      const file = fileInput.files[0];

      if (file) {
        // Validate file type
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/webp",
        ];
        if (!allowedTypes.includes(file.type)) {
          productChangeMessage.error(
            "Invalid file type. Please select an image."
          );
          return;
        }

        // Validate image dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            const minWidth = 1536; // Example minimum width
            const minHeight = 956; // Example minimum height

            if (width >= minWidth && height >= minHeight) {
              // Image is valid, proceed with file upload
              const formData = new FormData();
              formData.append("image", file);

              axios
                .post(
                  `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_UPLOAD_ENDPOINT}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${getToken()}`,
                    },
                  }
                )
                .then((response) => {
                  // Update form state with the new image
                  const updatedImages = [
                    ...addForm[index].images,
                    response.data.data.image[0],
                  ];
                  const updatedData = addForm.map((item, i) =>
                    index === i ? { ...item, images: updatedImages } : item
                  );
                  setAddForm(updatedData);
                })
                .catch((error) => { });
            } else {
              // Invalid image dimensions
              productChangeMessage.error(
                "Invalid dimensions for image. Please select an image with a width of 1536 pixels and a height of 956 pixels."
              );
            }
          };
        };

        // Read the file to get dimensions
        reader.readAsDataURL(file);
      }
    } catch (error) { }
  };

  const addImageUploadEditVariant = async (e, index) => {
    try {
      const fileInput = e.target;
      const file = fileInput.files[0];
      if (file) {
        // Validate file type
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/webp",
        ];
        if (!allowedTypes.includes(file.type)) {
          productChangeMessage.error(
            "Invalid file type. Please select an image."
          );
          return;
        }
        // Validate image dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const minWidth = 1536; // Example minimum width
            const minHeight = 956; // Example minimum height
            if (width >= minWidth && height >= minHeight) {
              // Image is valid, proceed with file upload
              const formData = new FormData();
              formData.append("image", file);
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_UPLOAD_ENDPOINT}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${getToken()}`,
                    },
                  }
                )
                .then((response) => {
                  // Update form state with the new image
                  const updatedImages = [
                    ...editFormVariantsAddForm[index].images,
                    response.data.data.image[0],
                  ];
                  const updatedData = editFormVariantsAddForm.map((item, i) =>
                    index === i ? { ...item, images: updatedImages } : item
                  );
                  setEditFormVariantsAddForm(updatedData);
                })
                .catch((error) => { });
            } else {
              // Invalid image dimensions
              productChangeMessage.error(
                "Invalid dimensions for image. Please select an image with a width of 1536 pixels and a height of 956 pixels."
              );
            }
          };
        };
        // Read the file to get dimensions
        reader.readAsDataURL(file);
      }
    } catch (error) { }
  };

  return (
    <>
      <Layout>
        <div className={style.productsChange_section}>
          <BackButton
            name={path === urls ? "products add" : "products update"}
            backButtonHandler={backHandler}
          />
          {path === urls ? (
            <div className={style.appointments_form}>
              {addForm &&
                addForm.map((item, index) => {
                  return (
                    <div key={index} className={style.appointments_form}>
                      {index === 0 ? (
                        ""
                      ) : (
                        <div className={style.appointments_form_varient}>
                          variant {index}
                        </div>
                      )}
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        label="Product Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your product name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your product name"
                          name="name"
                          disabled={index > 0 ? true : false}
                          value={addForm[index].name}
                          onChange={(e) => variantChange(e, index)}
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"name"}
                        />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        label="Product Size"
                        name="size"
                        rules={[
                          {
                            required: true,
                            message: "Please input your product name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your product size"
                          name="size"
                          value={addForm[index].size}
                          onChange={(e) => variantChange(e, index)}
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"size"}
                        />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        label="Product Quantity"
                      // name="quantity"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input your product quantity!",
                      //   },
                      // ]}
                      >
                        <Input
                          placeholder="Enter your product quantity"
                          name="quantity"
                          value={addForm[index].quantity}
                          onChange={(e) => variantChange(e, index)}
                          disabled
                        />
                        {/* <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"quantity"}
                        /> */}
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        label="Product Type"
                        name="productType"
                      >
                        <Input
                          placeholder="Enter your product type"
                          name="productType"
                          // disabled={index > 0 ? true : false}
                          value={addForm[index].productType}
                          onChange={(e) => variantChange(e, index)}
                          disabled
                        />
                        {/* <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"productType"}
                        /> */}
                      </Form.Item>
                      <div className={style.appointments_form_color_picker}>
                        <label htmlFor="product color">Product Color</label>
                        <Input
                          style={{ height: "54px" }}
                          type="color"
                          name="colour"
                          value={addForm[index].colour}
                          onChange={(e) => variantChange(e, index)}
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"colour"}
                        />
                      </div>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        label="Product Specifications"
                        name="specifications"
                        rules={[
                          {
                            required: true,
                            message: "Please input your product name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your product specifications"
                          name="specifications"
                          disabled={index > 0 ? true : false}
                          value={addForm[index].specifications}
                          onChange={(e) => variantChange(e, index)}
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"specifications"}
                        />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        label="Product Price"
                        // name="price"
                        rules={[
                          {
                            required: true,
                            message: "Please input your product price!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your product price"
                          name="price"
                          value={addForm[index].price}
                          onChange={(e) => variantChange(e, index)}
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"price"}
                        />
                      </Form.Item>
                      <div className={style.appointments_form_custom_select}>
                        <label htmlFor="Discount Type">Discount Type</label>
                        <br />
                        <select
                          placeholder="Enter your discount type"
                          onChange={(e) => variantChange(e, index)}
                          name="discountType"
                          value={addForm[index].discountType}
                        >
                          <option>select discount type</option>
                          {discountData &&
                            discountData.map((size, index) => (
                              <option value={size.value}>{size.value}</option>
                            ))}
                        </select>
                      </div>
                      <div className={style.appointments_form_custom_select}>
                        <label htmlFor="Discount">Discount</label>
                        <br />
                        <input
                          placeholder="Enter your discount"
                          name="discount"
                          value={addForm[index].discount}
                          onChange={(e) => variantChange(e, index)}
                        />
                      </div>
                      <div className={style.appointments_form_custom_select}>
                        <label htmlFor="discounted Price">
                          Discounted Price
                        </label>
                        <br />
                        <input
                          disabled
                          placeholder="Enter your product discount"
                          name="discountedPrice"
                          value={addForm[index].discountedPrice}
                          onChange={(e) => variantChange(e, index)}
                        />
                      </div>

                      <div className={style.appointments_form_custom_select}>
                        <label htmlFor="Product Type">Product material</label>
                        <br />
                        <select
                          placeholder="Enter your product material"
                          onChange={(e) => variantChange(e, index)}
                          name="material"
                          value={addForm[index].material}
                        >
                          <option>select product material</option>
                          {materialData &&
                            materialData.map((size, index) => (
                              <option value={size.value}>{size.value}</option>
                            ))}
                        </select>
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"material"}
                        />
                      </div>
                      <div className={style.appointments_form_custom_select}>
                        <label htmlFor="Product Category">
                          <span>*</span>Product Category
                        </label>
                        <br />
                        <select
                          placeholder="Enter your product category"
                          onChange={(e) => variantChange(e, index)}
                          name="category"
                          disabled={index > 0 ? true : false}
                          value={addForm[index].category}
                        >
                          <option>select product category</option>
                          {category &&
                            category.map((size, index) => (
                              <option value={size}>{size}</option>
                            ))}
                        </select>
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"category"}
                        />
                      </div>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        style={{ width: "100%" }}
                        label="Details"
                        name="details"
                      >
                        <TextArea
                          rows={4}
                          placeholder="details here...."
                          name="details"
                          disabled={index > 0 ? true : false}
                          value={addForm[index].details}
                          onChange={(e) => variantChange(e, index)}
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"details"}
                        />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        style={{ width: "100%" }}
                        label="Description"
                        name="description"
                        disabled
                      >
                        <TextArea
                          rows={4}
                          placeholder="description here...."
                          name="description"
                          // disabled={index > 0 ? true : false}
                          value={addForm[index].description}
                          onChange={(e) => variantChange(e, index)}
                          disabled
                        />
                        {/* <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"description"}
                        /> */}
                      </Form.Item>
                      <div className={style.images_dimensions}>
                        the images dimensions should be (1536)x(956).
                      </div>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        className={style.appointments_form_item}
                        style={{
                          background: "#fff",
                          borderRadius: "7px",
                          paddingLeft: "5px",
                          width: "100%",
                        }}
                        label="Image"
                        name="image"
                      >
                        <div className={style.common_edit_image_antd}>
                          {item.images?.length > 0
                            ? item.images &&
                            item.images.map((item, imgIndex) => {
                              return (
                                <div
                                  className={
                                    style.common_edit_image_antd_container
                                  }
                                  key={imgIndex}
                                >
                                  <img
                                    src={item ? `${mainUrl()}/${item}` : "-"}
                                    alt="edit image"
                                  />
                                  <div
                                    className={style.common_edit_delt_antd}
                                  >
                                    <img
                                      src={DeleteImg}
                                      alt="delete image"
                                      onClick={() =>
                                        deleteImgHandlerAdd(index, item)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })
                            : ""}
                        </div>
                        <button
                          className={style.custom_file_button}
                          onClick={handleButtonClick}
                        >
                          + upload
                        </button>
                        <input
                          type="file"
                          name="image"
                          ref={fileInputRef}
                          className={style.custom_file_input}
                          onChange={(e) => addImageUpload(e, index)}
                          multiple
                        />
                        <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={index}
                          field={"images"}
                        />
                      </Form.Item>
                      {index === 0 ? (
                        ""
                      ) : (
                        <div
                          className={style.appointments_form_add_delete}
                          onClick={() => variantDelete(index)}
                        >
                          <div
                            className={style.delete_edit_hand}
                            style={{ marginLeft: "5px" }}
                          >
                            <>
                              <img src={DeleteImg} alt="edit image" />
                              <div>delete</div>
                            </>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              <div className={style.appointments_form_add_delete}>
                <div
                  className={style.delete_edit_hand_1}
                  onClick={addFormHnadler}
                >
                  <img src={AddImg} alt="edit image" />
                  <div>add variant</div>
                </div>
              </div>
              <div className={style.appointments_form_submit_all}>
                <div
                  className={style.appointments_form_submit}
                  onClick={addDataProduct}
                >
                  <div>{loading ? <SmallLoader /> : "add products"}</div>
                </div>
              </div>
            </div>
          ) : (
            //product update form
            <Form
              className={style.appointments_form}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout={"vertical"}
              form={form}
              style={{
                maxWidth: "100%",
              }}
            >
              <Form.Item
                className={style.appointments_form_item}
                label="Product Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your product name!",
                  },
                ]}
              >
                <Input placeholder="Enter your product name" />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Product Size"
                name="size"
              >
                <Input placeholder="Enter your product size" />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Product Quantity"
                name="quantity"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your product quantity!",
              //   },
              // ]}
              >
                <Input
                  placeholder="Enter your product quantity"
                  onChange={handleEditQuantityChange}
                  disabled
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Product Type"
                name="type"
              >
                <Input placeholder="Enter your product type"
                  disabled
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_color_picker_edit}
                label="Product Color"
                name="colour"
              >
                <Input
                  style={{ height: "57px", marginTop: "-3px" }}
                  type="color"
                  placeholder="Enter your product color"
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Product Specifications"
                name="specifications"
              >
                <Input placeholder="Enter your product specifications" />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Product Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please input your product price!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your product price"
                  // onChange={updateDiscountHanddlerPrice}
                  onChange={handleEditPriceChange}
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Discount Type"
                name="discountType"
              >
                <Select
                  className={style.appointments_form_item}
                  style={{
                    marginTop: "-5px",
                    border: " 1px solid #3E3636",
                    borderRadius: "5px",
                  }}
                  showSearch
                  placeholder="Enter your discount type"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={discountData}
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="discount"
                name="discountAdd"
              >
                <Input
                  placeholder="Enter your product discount"
                  // onChange={updateDiscountHanddler}
                  onChange={handleEditDiscountChange}
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Discounted Price "
                name="discount"
              >
                <Input placeholder="Enter your product discount" disabled />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Product Material"
                name="material"
              >
                {/* <Input placeholder="Enter your product material"/> */}
                <Select
                  className={style.appointments_form_item}
                  style={{
                    marginTop: "-5px",
                    border: " 1px solid #3E3636",
                    borderRadius: "5px",
                  }}
                  showSearch
                  placeholder="Enter your product material"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={materialData}
                />
              </Form.Item>
              {/* <Form.Item
                className={style.appointments_form_item}
                label="Product Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please input your product category!",
                  },
                ]}
              >
                <Input placeholder="Enter your product category" />
              </Form.Item> */}

              <Form.Item
                className={style.appointments_form_item}
                label="Product Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please input your product Category!",
                  },
                ]}
              >
                <Select
                  className={style.appointments_form_item}
                  style={{
                    marginTop: "-5px",
                    border: " 1px solid #3E3636",
                    borderRadius: "5px",
                  }}
                  showSearch
                  placeholder="Enter your product category"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={updateCategory}
                // disabled
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                style={{ width: "100%" }}
                label="Details"
                name="details"
              >
                <TextArea rows={4} placeholder="details here...." />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                style={{ width: "100%" }}
                label="Description"
                name="description"
              >
                <TextArea rows={4} placeholder="description here...."
                  disabled
                />
              </Form.Item>
              <div className={style.images_dimensions}>
                the images dimensions should be (1536)X(956).
              </div>
              <Form.Item
                className={style.appointments_form_item_image}
                style={{
                  background: "#fff",
                  borderRadius: "7px",
                  paddingLeft: "5px",
                  width: "100%",
                }}
                label="Image"
                name="image"
              >
                <div className={style.common_edit_image_antd}>
                  {newImage?.length > 0
                    ? newImage &&
                    newImage.map((item, index) => {
                      return (
                        <div
                          className={style.common_edit_image_antd_container}
                          key={index}
                        >
                          <img
                            src={
                              newImage ? `${mainUrl()}/${item.image}` : "-"
                            }
                            alt="edit image"
                          />
                          <div className={style.common_edit_delt_antd}>
                            <img
                              src={DeleteImg}
                              alt="delete image"
                              onClick={() => deleteImgHandler(item.image)}
                            />
                          </div>
                        </div>
                      );
                    })
                    : ""}
                </div>
                <div>
                  <Upload
                    beforeUpload={beforeUpload}
                    action={trelifImageUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => { }}
                    {...propsUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImage.length < 10 && "+ Upload"}
                  </Upload>
                </div>
              </Form.Item>
              <div>
                {editFormVariantsAddForm.length ? (
                  <>
                    {editFormVariantsAddForm.map((variantForm, idx) => {
                      return (
                        <>
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "1rem",
                              fontWeight: "600",
                            }}
                          > */}
                          <Divider className={style.divider}>
                            Variant {idx + 1}
                          </Divider>
                          {/* </div> */}
                          <Form
                            className={style.appointments_form}
                            name="basic"
                            // onFinish={onFinishVariant}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout={"vertical"}
                            // form={form}
                            style={{
                              maxWidth: "100%",
                            }}
                          >
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Name"
                              // name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product name!",
                                },
                              ]}
                            >
                              <Input
                                disabled
                                value={variantForm.name}
                                // onChange={(e) =>
                                //   addVariantChange(e, idx, "name")
                                // }
                                placeholder="Enter your product name"
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Size"
                              name="size"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product name!",
                                },
                              ]}
                            >
                              <Input
                                value={variantForm.size}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "size")
                                }
                                placeholder="Enter your product size"
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Quantity"
                              name="quantity"
                            // rules={[
                            //   {
                            //     required: false,
                            //     message:
                            //       "Please input your product quantity!",
                            //   },
                            // ]}
                            >
                              <Input
                                placeholder="Enter your product quantity"
                                value={variantForm.quantity}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "quantity")
                                }
                                disabled
                              // onChange={handleEditQuantityChange}
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Type"
                            // name="type"
                            >
                              <Input
                                disabled
                                value={variantForm.productType}
                                // onChange={(e) =>
                                //   addVariantChange(e, idx, "productType")
                                // }
                                placeholder="Enter your product type"
                              />
                            </Form.Item>
                            <Form.Item
                              className={
                                style.appointments_form_color_picker_edit
                              }
                              label="Product Color"
                              name="colour"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product name!",
                                },
                              ]}
                            >
                              <Input
                                style={{ height: "57px", marginTop: "-3px" }}
                                type="color"
                                placeholder="Enter your product color"
                                value={variantForm.colour}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "colour")
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Specifications"
                            // name="specifications"
                            >
                              <Input
                                disabled={true}
                                value={variantForm.specifications}
                                // onChange={(e) =>
                                //   addVariantChange(e, idx, "specifications")
                                // }
                                placeholder="Enter your product specifications"
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Price"
                              name="price"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product price!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter your product price"
                                // onChange={updateDiscountHanddlerPrice}
                                // onChange={handleEditPriceChange}
                                value={variantForm.price}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "price")
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Discount Type"
                              name="discountType"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product name!",
                                },
                              ]}
                            >
                              <Select
                                className={style.appointments_form_item}
                                style={{
                                  marginTop: "-5px",
                                  border: " 1px solid #3E3636",
                                  borderRadius: "5px",
                                }}
                                // showSearch
                                placeholder="Enter your discount type"
                                optionFilterProp="children"
                                // onChange={onChangeSelect}
                                value={variantForm.discountType}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "discountType")
                                }
                                // onSearch={onSearch}
                                filterOption={filterOption}
                                options={discountData}
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="discount"
                              name="discountAdd"
                            >
                              <Input
                                placeholder="Enter your product discount"
                                value={variantForm.discount}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "discount")
                                }
                              // onChange={updateDiscountHanddler}
                              // onChange={handleEditDiscountChange}
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Discounted Price"
                            // name="discount"
                            >
                              <Input
                                value={variantForm.discountedPrice}
                                // onChange={(e) =>
                                //   addVariantChange(e, idx, "discountedPrice")
                                // }
                                placeholder="Enter your product discount"
                                disabled
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Material"
                              name="material"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product name!",
                                },
                              ]}
                            >
                              {/* <Input placeholder="Enter your product material"/> */}
                              <Select
                                className={style.appointments_form_item}
                                style={{
                                  marginTop: "-5px",
                                  border: " 1px solid #3E3636",
                                  borderRadius: "5px",
                                }}
                                showSearch
                                placeholder="Enter your product material"
                                optionFilterProp="children"
                                // onChange={onChangeSelect}
                                value={variantForm.material}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "material")
                                }
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={materialData}
                              />
                            </Form.Item>
                            {/* <Form.Item
              className={style.appointments_form_item}
              label="Product Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please input your product category!",
                },
              ]}
            >
            <Input placeholder="Enter your product category"/>
            </Form.Item> */}

                            <Form.Item
                              className={style.appointments_form_item}
                              label="Product Category"
                              // name="category"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please input your product Category!",
                                },
                              ]}
                            >
                              <Select
                                className={style.appointments_form_item}
                                style={{
                                  marginTop: "-5px",
                                  border: " 1px solid #3E3636",
                                  borderRadius: "5px",
                                }}
                                showSearch
                                placeholder="Enter your product category"
                                optionFilterProp="children"
                                // onChange={onChangeSelect}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={updateCategory}
                                disabled={true}
                                value={variantForm.category}
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              style={{ width: "100%" }}
                              label="Details"
                            // name="details"
                            >
                              <TextArea
                                disabled={true}
                                rows={4}
                                placeholder="details here...."
                                value={variantForm.details}
                              // onChange={(e) =>
                              //   addVariantChange(e, idx, "details")
                              // }
                              />
                            </Form.Item>
                            <Form.Item
                              className={style.appointments_form_item}
                              style={{ width: "100%" }}
                              label="Description"
                              name="description"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your product name!",
                                },
                              ]}
                            >
                              <TextArea
                                rows={4}
                                placeholder="description here...."
                                value={variantForm.description}
                                onChange={(e) =>
                                  addVariantChange(e, idx, "description")
                                }
                                disabled={true}
                              />
                            </Form.Item>
                            <div className={style.images_dimensions}>
                              the images dimensions should be (1536)x(956).
                            </div>
                            <Form.Item
                              labelCol={{ span: 24 }}
                              className={style.appointments_form_item}
                              style={{
                                background: "#fff",
                                borderRadius: "7px",
                                paddingLeft: "5px",
                                width: "100%",
                              }}
                              label="Image"
                              name="image"
                            >
                              <div className={style.common_edit_image_antd}>
                                {variantForm.images?.length > 0
                                  ? variantForm.images &&
                                  variantForm.images.map((item, imgIndex) => {
                                    return (
                                      <div
                                        className={
                                          style.common_edit_image_antd_container
                                        }
                                        key={imgIndex}
                                      >
                                        <img
                                          src={
                                            item
                                              ? `${mainUrl()}/${item}`
                                              : "-"
                                          }
                                          alt="edit image"
                                        />
                                        <div
                                          className={
                                            style.common_edit_delt_antd
                                          }
                                        >
                                          <img
                                            src={DeleteImg}
                                            alt="delete image"
                                            onClick={() =>
                                              deleteImgHandlerEditVariant(
                                                idx,
                                                item
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                                  : ""}
                              </div>
                              <button
                                className={style.custom_file_button}
                                onClick={() =>
                                  handleButtonClickEditVariant(idx)
                                }
                                type="button"
                              >
                                + upload
                              </button>
                              <input
                                type="file"
                                name="image"
                                // ref={fileInputRef2}
                                ref={(ele) =>
                                  (fileInputRefs.current[idx] = ele)
                                }
                                className={style.custom_file_input}
                                onChange={(e) =>
                                  addImageUploadEditVariant(e, idx)
                                }
                                multiple
                              />
                              {/* <InputError
                          showInputErrors={showInputErrors}
                          inputErrors={inputErrors}
                          index={idx}
                          field={"images"}
                        /> */}
                            </Form.Item>
                          </Form>
                        </>
                      );
                    })}
                  </>
                ) : null}
              </div>
              <Form.Item className={style.appointments_form_submit_all}>
                <Button
                  className={style.appointments_form_submit}
                  style={{ color: "#fff" }}
                  onClick={handleAddVariantOnEdit}
                  hidden
                >
                  Add Variants
                </Button>
              </Form.Item>
              {!editFormVariantsAddForm.length ? (
                <Form.Item className={style.appointments_form_submit_all}>
                  <Button
                    className={style.appointments_form_submit}
                    // type="primary"
                    htmlType="submit"
                    style={{ color: "#fff" }}
                  >
                    {loading ? <SmallLoader /> : "update products"}
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item className={style.appointments_form_submit_all}>
                  <Button
                    className={style.appointments_form_submit}
                    // htmlType="submit"
                    htmlType="button"
                    style={{ color: "#fff" }}
                    onClick={handleSubmitVariants}
                  >
                    {loader ? <SmallLoader /> : "submit variants"}
                  </Button>
                </Form.Item>
              )}
            </Form>
          )}
        </div>
      </Layout>
    </>
  );
};
export default ProductsChange;
