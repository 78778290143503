import React, { useState, useEffect } from "react";
import style from "./appointments.module.css";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pageHeader/PageHeader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/icons8-edit-24.png";
import RevertImg from "../../assets/revert.png";
import Tables from "../../components/tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentsData,
  appointmentsDataRevert,
} from "../../store/slices/appointmentsSlice";
import moment from "moment";
import { useNavigate } from "react-router";
import { Space, Tag, message } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import ActionImg from "../../assets/actionImg.png";
import AppointmentsPopup from "../../components/appointmentsPopup/AppointmentsPopup";
import PeekModal from "../../components/peekModal/PeekModal";

const Appointments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.appointments.data);
  const loading = useSelector((state) => state.appointments.loading);
  const revertLoad = useSelector((state) => state.appointments.revertLoad);
  const message = useSelector((state) => state.appointments.message);
  const [state, setState] = useState();
  const [revertId, setRevertId] = useState();
  const [shows, setShows] = useState(false);
  const [pops, setPops] = useState();
  const [openModal, setOpenModal] = useState({ text: "", open: false });

  useEffect(() => {
    dispatch(getAppointmentsData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  useEffect(() => {
    if (message === "Appointment has been reverted") {
      dispatch(getAppointmentsData());
      closeHandlers();
    }

  }, [message]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      id: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      id: "email",
    },
    {
      title: "Type",
      dataIndex: "selectedOption",
      id: "selectedOption",
      render: (text) => <a>{text[0].toUpperCase() + text.slice(1)}</a>,
    },
    // {
    //   title: "Message",
    //   dataIndex: "message",
    //   id: "message",
    // },
    {
      title: "Message",
      dataIndex: "message",
      id: "message",
      width: 200,
      render: (text) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              maxHeight: "4vh",
              maxWidth: "18ch",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text ? text : "NA"}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setOpenModal({ ...openModal, text: text, open: true })
            }
          >
            <img src={ActionImg} alt="Open" />
          </div>
        </div>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      id: "phone",
    },
    {
      title: "Date",
      dataIndex: "date",
      id: "date",
      render: (date) => <a>{moment(date).format("DD/MM/YYYY")}</a>,
    },
    {
      title: "Preferred Time",
      // dataIndex: "startTime",
      // id: "startTime",
      id: "preferredTime",
      render: (_, record) => {
        return (
          <Space size="middle">
            {record.startTime}
            &mdash; {record.endTime}
          </Space>
        );
      },
    },
    // {
    //   title: 'End Time',
    //   dataIndex: 'endTime',
    //   id: 'endTime',
    // },
    {
      title: "Action",
      id: "action",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <div className={style.orders_section_product_pp}>
            {" "}
            <img
              src={ActionImg}
              onClick={() => productHandlerPop(record)}
              alt="image"
            />
          </div>
          {shows ? (
            <AppointmentsPopup
              data={pops}
              backHandler={closeHandlers}
              label="appointments email reply"
            />
          ) : (
            ""
          )}
        </Space>
      ),
    },
    // {
    //   title: 'Action',
    //   id: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
    //       {revertLoad && record._id=== revertId ? <RevertLoader/>:
    //       <>
    //       <img src={RevertImg} alt='edit image'/>
    //        <div>revert</div>
    //        </>
    //        }
    //       </div>
    //     </Space>
    //   ),
    // },
  ];
  const deleteHandler = (record) => {
    setRevertId(record._id);
    dispatch(appointmentsDataRevert(record._id));
  };
  const productHandlerPop = (record) => {
    setShows(true);
    setPops(record);
  };
  const closeHandlers = () => {
    setShows(false);
    setPops();
  };
  return (
    <>
      <PeekModal openModal={openModal} setOpenModal={setOpenModal} />
      <Layout>
        <div className={style.appointments_section}>
          <PageHeader
            name="appointments details"
            count={state ? state.length : ""}
            total={true}
          />
          {loading ? (
            <BigLoader />
          ) : (
            <>
              <Tables datas={state} columns={columns} scrollVal={1100} />
              <div style={{ textTransform: "capitalize" }}>
                total appointments -{" " + state ? state?.length : ""}
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Appointments;
